<template>
  <div class="wrapper bg-nColorOrange">
    <div v-if="started && gameData.game_details">
      <div class="flex flex-col flex-grow justify-around">
        <iframe
          id="doodlematch_iframe"
          @load="loadedIframe()"
          class="w-full h-screen"
          :src="iframeSrc"
          frameborder="0"
        ></iframe>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";

export default {
  name: "DoodleMatch",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { TimeElapsed },
  data() {
    return {
      iframeSrc: process.env.VUE_APP_EXP_DOODLE_MATCH_IFRAME_URL,
      score: null,
      started: false,
    };
  },
  created() {
    this.startGame();
  },
  methods: {
    startGame() {
      this.started = true;
      const vue = this;
      window.onmessage = function (e) {
        try {
          const data = JSON.parse(e.data);
          if (data.type == "score_playda") {
            vue.score = data.score;
            vue.submitScore();
          } else if (data.type == "enter_fullscreen") {
            vue.iframeFullScreen("enter");
          }
        } catch (error) {}
      };
    },
    loadedIframe() {
      const data = JSON.stringify({
        type: "token_playda",
        token: localStorage.token,
        game_data: this.gameData.game_data,
      });

      document
        .getElementById("doodlematch_iframe")
        .contentWindow.postMessage(data, "*");
    },

    submitScore() {
      ApiService.post(apiResource.saveDoodleMatchScore, {
        business_slug: this.$route.params.business,
        score: this.score,
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
      })
        .then(() => {
          setTimeout(() => {
            const url = new URL(window.location.href);
            url.searchParams.set("redirect", "gameover");
            url.searchParams.set(
              "game_session_id",
              this.gameData.game_session_id
            );
            window.location.href = url.toString();
          }, 1000);
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    iframeFullScreen(type) {
      let iframeElement = document.getElementById("doodlematch_iframe");
      if (type == "enter") {
        iframeElement.classList.add("fullscreen");
        document.body.style.position = "fixed";
      } else {
        iframeElement.classList.remove("fullscreen");
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
.fullscreen {
  @apply absolute top-0 left-0 w-screen overflow-hidden z-50;
  height: 100dvh;
}
</style>
