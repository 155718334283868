<template>
  <div>
    <div v-if="data">
      <div v-if="startGame && gameDetails">
        <component
          :is="game"
          :schedule_id="$route.params.schedule_id"
          :gameData="gameDetails"
        >
        </component>
      </div>
      <div class="wrapper bg-black flex flex-col items-center pt-4" v-else>
        <div class="flex justify-between px-4">
          <div>
            <img :src="logo" class="w-1/3" />
          </div>
          <div>
            <button
              @click.prevent="goBackToLobby()"
              class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-5 py-0.5 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
            >
              Back
            </button>
          </div>
        </div>
        <div class="flex justify-center pt-2">
          <img :src="data.ui.how_to_play_full" class="w-10/12" />
        </div>
        <!-- <div
          id="gameName"
          class="font-ZuumeSemiBold text-5xl text-nColorYellow mt-2 uppercase flex"
          v-if="data"
        >
          <p class="relative" v-if="data.name">
            {{ data.name }}
          </p>
        </div>
        <div
          class="how-to-play-wrapper relative bg-nColorOrange w-10/12 mx-10 p-4 rounded-xl triple-border"
        >
          <img
            :src="data.ui.how_to_play_image"
            class="how-to-play-image absolute left-1/2 transform -translate-x-1/2 -mt-1 z-10"
          />
          <div class="pb-1" v-if="data.ui.description">
            <p class="font-InterMedium italic text-xs pb-1 pt-7">
              {{ data.ui.description }}
            </p>
            <hr class="border-t border-gray-800" />
          </div>
          <div v-if="data.how_to_play && data.how_to_play.type === 'bullet'">
            <div
              v-for="(point, index) in data.how_to_play.content"
              :key="index"
              class="flex items-center gap-1 mt-4 mb-2"
            >
              <img :src="require('@/assets/images/bullet-star-yellow.svg')" />
              <p class="font-ZuumeRegular text-lg">{{ point }}</p>
            </div>
          </div>
          <div v-if="data.how_to_play && data.how_to_play.type === 'slider'">
            <div class="image-slider">
              <img :src="currentImage" />
              <div class="flex gap-2 items-center justify-center">
                <div>
                  <div class="prev-btn" @click="prevImage">
                    <img
                      :src="
                        require('@/assets/images/game-page/button_arrow.png')
                      "
                      alt="Previous"
                      class="w-12"
                    />
                  </div>
                </div>
                <div>
                  <div class="next-btn" @click="nextImage">
                    <img
                      :src="
                        require('@/assets/images/game-page/button_arrow.png')
                      "
                      alt="Next"
                      class="w-12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="grid place-content-center mb-2"
            v-if="data.how_to_play && data.how_to_play.type === 'custom-bullet'"
          >
            <div class="flex">
              <div
                v-for="(point, index) in data.how_to_play.content"
                :key="index"
                class="grid"
              >
                <div class="h-20 flex items-end">
                  <img :src="point.bullet" width="78" height="55" />
                </div>
                
                <p class="font-ZuumeSemiBold text-center">
                  {{ point.desc }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="data.how_to_play && data.how_to_play.type === 'img_txt'"
            class="grid justify-items-center"
          >
            <img :src="data.how_to_play.content.img" class="pt-4" />
            <p class="font-ZuumeRegular text-lg text-center">
              {{ data.how_to_play.content.txt }}
            </p>
          </div>
        </div>
        <div
          class="mx-8 mt-4 text-sm font-InterSemiBold text-center text-white"
        >
          {{ data.ui.tagline }}
        </div> -->
        <div class="w-full absolute bottom-14">
          <div class="flex justify-center">
            <button
              id="lets-play-button"
              @click.prevent="playGame()"
              class="shadow-yellow-right border border-nColorYellow bg-black rounded-3xl w-5/6 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
            >
              Let's play
            </button>
          </div>
          <div class="flex justify-center mt-4">
            <button
              @click.prevent="goToLeaderboard()"
              class="shadow-orange-right border border-black bg-nColorYellow rounded-3xl w-5/6 text-black text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
            >
              Leaderboard
            </button>
          </div>
        </div>
        <Copyright />
      </div>
      <div id="noGameTimerWrapper" class="hidden">
        <div
          class="absolute top-0 left-0 w-full h-full bg-nColorDarkGray blurry-div z-10"
        ></div>
        <div
          id="no-game-content"
          class="no-game absolute w-10/12 h-72 bg-nColorYellow rounded-2xl z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-black-right border border-black text-center"
        >
          <p
            class="text-nColorOrange font-ZuumeSemiBold text-7xl pt-4 text-shadow-black-right"
          >
            Sorry!!
          </p>
          <p class="font-InterSemiBold pt-4">
            This game is no longer available.<br />
            Redirecting you to the lobby in
          </p>
          <span
            id="noGameTimer"
            class="font-ZuumeSemiBold text-9xl text-nColorOrange text-shadow-black-right"
            >5</span
          >
        </div>
      </div>
    </div>
    <div id="ratingPopUp" class="hidden">
      <div
        id="overlay-blur"
        class="absolute top-0 w-screen h-screen bg-nColorDarkGray blurry-div z-30"
      ></div>
      <div
        class="w-10/12 p-4 bg-nColorYellow rounded-xl absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-40 pb-10"
      >
        <div class="absolute -top-4 right-4">
          <img
            :src="require('@/assets/images/rating/close.svg')"
            @click.prevent="closeRatingPopup"
          />
        </div>
        <p
          class="text-nColorOrange font-ZuumeSemiBold text-4xl text-center mt-2 text-shadow-black-right-light"
        >
          Rate this game
        </p>
        <p class="font-InterSemiBold text-sm text-center">
          Your opinion matters to us.
        </p>
        <div
          class="w-10/12 bg-nColorOrange rounded-3xl text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-1 gap-2 absolute transform left-1/2 -translate-x-1/2 -bottom-5 border border-nColorDarkGray shadow-yellow-right"
        >
          <div class="flex gap-2">
            <img
              :src="require('@/assets/images/rating/1.svg')"
              id="1star"
              @click.prevent="selectRating('1')"
            />
            <img
              :src="require('@/assets/images/rating/2.svg')"
              id="2star"
              @click.prevent="selectRating('2')"
            />
            <img
              :src="require('@/assets/images/rating/3.svg')"
              id="3star"
              @click.prevent="selectRating('3')"
            />
            <img
              :src="require('@/assets/images/rating/4.svg')"
              id="4star"
              @click.prevent="selectRating('4')"
            />
            <img
              :src="require('@/assets/images/rating/5.svg')"
              id="5star"
              @click.prevent="selectRating('5')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TrueOrFalse from "@/components/games/TrueOrFalse.vue";
import GTM from "@/components/games/GTM.vue";
import Jigsaw from "@/components/games/Jigsaw.vue";
import WhatCameFirst from "@/components/games/WhatCameFirst.vue";
import FindMe from "@/components/games/FindMe.vue";
import MergeFaces from "@/components/games/MergeFaces.vue";
import Memorize from "@/components/games/Memorize.vue";
import Whacko from "@/components/games/Whacko.vue";
import MakeWords from "@/components/games/MakeWords.vue";
import ThePouringGame from "@/components/games/ThePouringGame.vue";
import DoodleMatch from "@/components/games/DoodleMatch.vue";
import FieldMaster from "@/components/games/FieldMaster.vue";
import BatMaster from "@/components/games/BatMaster.vue";
import HexaDrop from "@/components/games/HexaDrop.vue";
import LyricLingo from "@/components/games/LyricLingo.vue";
import Quizzical from "@/components/games/Quizzical.vue";
import FallingBlocks from "@/components/games/FallingBlocks.vue";
import Copyright from "@/components/elements/Copyright.vue";
import slugs from "@/common/slugs";

export default {
  name: "Game",
  components: {
    Jigsaw,
    TrueOrFalse,
    GTM,
    WhatCameFirst,
    FindMe,
    MergeFaces,
    Memorize,
    Whacko,
    MakeWords,
    ThePouringGame,
    DoodleMatch,
    FieldMaster,
    BatMaster,
    HexaDrop,
    LyricLingo,
    Quizzical,
    FallingBlocks,
    Copyright,
  },
  data() {
    return {
      logo: this.$store.getters.businessLogo,
      data: null,
      game: null,
      startGame: false,
      gameDetails: null,
      noGameSeconds: 0,
      howToPlays: [],
      currentIndex: 0,
      gameSessionId: null,
      timerIntervalId: null,
      slugs,
    };
  },
  created() {
    if (!this.$route.query.redirect) {
      ApiService.get(apiResource.getGameDetails, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        game: this.$route.params.game,
      }).then((data) => {
        this.data = data.data.data;
        this.game = slugs.gameComponents[slugs[this.$route.params.game]];
        if (this.data.how_to_play.type === "slider") {
          this.howToPlays = this.data.how_to_play.content;
        }
      });
    }
  },
  mounted() {
    if (
      this.$route.query.redirect &&
      this.$route.query.redirect === "gameover"
    ) {
      this.gameSessionId = this.$route.query.game_session_id;
      this.goToGameOver();
    }
  },
  methods: {
    playGame() {
      ApiService.post(apiResource.updatePlayGameStatus, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        game: this.$route.params.game,
      })
        .then((data) => {
          this.startGame = true;
          this.gameDetails = data.data.data;
        })
        .catch(() => {
          document
            .getElementById("noGameTimerWrapper")
            .classList.remove("hidden");
          this.noGameSeconds = 5;
          const timerElement = document.getElementById("noGameTimer");
          this.timerIntervalId = setInterval(() => {
            this.noGameSeconds--;
            timerElement.innerHTML = `${this.noGameSeconds}`;
            if (this.noGameSeconds === 0) {
              clearInterval(this.timerIntervalId);
              this.goBackToLobby();
            }
          }, 1000);
        });
    },
    getTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.startDateTime - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    goBackToLobby() {
      this.$router.replace({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    goToLeaderboard() {
      this.$router.replace({
        name: "Leaderboard",
        params: {
          business: this.$route.params.business,
          schedule_id: this.$route.params.schedule_id,
        },
      });
    },
    goToGameOver() {
      ApiService.get(apiResource.getUserFeedbackForSchedule, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
      }).then((data) => {
        var feedbackDetails = data.data.data;
        if (feedbackDetails.has_given_feedback) {
          this.goToLeaderboard();
        } else {
          document.getElementById("ratingPopUp").classList.remove("hidden");
        }
      });
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.howToPlays.length;
    },
    prevImage() {
      this.currentIndex =
        (this.currentIndex - 1 + this.howToPlays.length) %
        this.howToPlays.length;
    },
    selectRating(rating) {
      for (var i = 1; i <= 5; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + ".svg");
      }
      for (i = 1; i <= rating; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + "-selected.svg");
      }
      ApiService.post(apiResource.saveGameFeedback, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        rating: rating,
      })
        .then(() => {
          this.goToLeaderboard();
        })
        .catch(() => {
          this.goToLeaderboard();
        });
    },
    closeRatingPopup() {
      document.getElementById("ratingPopUp").classList.add("hidden");
    },
  },
  computed: {
    currentImage() {
      return this.howToPlays[this.currentIndex];
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
.how-to-play-wrapper {
  margin-top: 6.5rem;
}
.how-to-play-image {
  top: -8rem;
}
.how-to-play-points {
  @apply pt-4;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.blurry-div {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
}
.prev-btn {
  transform: rotate(180deg);
}
@media (max-width: 360px) {
  .how-to-play-wrapper {
    margin-top: 5.5rem;
  }
  .how-to-play-points {
    @apply pt-0.5;
  }
}
</style>
