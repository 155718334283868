<template>
  <div
    class="counterWrapper bg-nColorDarkGray rounded-t-lg px-10 py-1 relative z-10 w-48 text-center"
  >
    <img
      :src="require('@/assets/images/timer-smile.svg')"
      class="absolute -top-2 -left-3"
    />
    <h2
      id="counterText"
      ref="counterText"
      class="font-ZuumeExtraBold text-nColorYellow text-4xl flex"
    >
      <div class="w-28">{{ formatTimer(displayTime, displayTime.minute) }}</div>
      :
      <div class="w-28">{{ formatTimer(displayTime, displayTime.sec) }}</div>
      :
      <div class="w-28">
        {{ formatTimer(displayTime, displayTime.milliseconds) }}
      </div>
    </h2>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    displayTime: {
      required: true,
    },
  },
  methods: {
    formatTimer(displayTime, time) {
      if (displayTime.minute == 0 && displayTime.sec <= 10) {
        document
          .getElementById("counterText")
          .classList.remove("text-nColorYellow");
        document.getElementById("counterText").classList.add("text-nColorRed");
      }
      return time > 9 ? "" + time : "0" + time;
    },
  },
};
</script>

<style scoped></style>