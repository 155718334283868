<template>
  <div>
    <div id="gameOverWrapper" class="hidden">
      <div
        class="absolute top-0 left-0 w-full h-full bg-nColorDarkGray blurry-div z-10"
      ></div>
      <div
        id="no-game-content"
        class="no-game absolute w-10/12 h-80 bg-nColorYellow rounded-2xl z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-black-right border border-black text-center"
      >
        <p
          class="text-nColorOrange font-ZuumeSemiBold text-7xl pt-4 text-shadow-black-right"
        >
          Oops!!
        </p>
        <p class="font-InterSemiBold pt-4 px-2">
          This game is over and the leaderboard has been announced.
        </p>
        <p class="font-InterRegular text-sm text-nColorLabelGray pt-4">
          Redirecting you to the leaderboard in
        </p>
        <span
          id="gameOverTimer"
          class="font-ZuumeSemiBold text-8xl text-nColorOrange text-shadow-black-right"
          >5</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeElapsed",
  methods: {
    startTimer() {
      document.getElementById("gameOverWrapper").classList.remove("hidden");
      this.noGameSeconds = 5;
      const timerElement = document.getElementById("gameOverTimer");
      setInterval(() => {
        this.noGameSeconds--;
        timerElement.innerHTML = `${this.noGameSeconds}`;
        if (this.noGameSeconds === 0) {
          clearInterval(this.timerElement);
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "leaderboard");
          window.location.href = url.toString();
        }
      }, 1000);
    },
  },
};
</script>

<style scoped></style>