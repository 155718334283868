<template>
  <div class="wrapper bg-nColorYellow">
    <div
      v-if="started && gameData.game_details"
      class="jigsaw-exp grid top-0 left-0 w-full items-start mt-10 place-items-center"
    >
      <div class="font-ZuumeSemiBold text-5xl text-nColorOrange uppercase flex">
        Jig
        <p class="rotateElement -mt-1 ml-0.5">S</p>
        <span>aw</span>
      </div>
      <p class="font-ZuumeRegular mt-4">Time left to solve the puzzle</p>
      <Counter
        class="relative mx-16"
        v-if="started"
        :displayTime="displayTime"
      />
      <div class="jigsaw-container">
        <transition name="fade">
          <!-- JIGSAW CANVAS -->
          <div id="canvas-wrap" v-if="!completed">
            <canvas id="canvas"></canvas>
            <canvas class="hide" id="image"></canvas>
            <canvas class="hide" id="image-preview"></canvas>
          </div>
          <div
            class="completed h-full flex flex-col justify-around items-center p-4 md:p-0"
            v-else
          >
            <h2 class="text-lg font-bold text-white text-center" v-if="solved">
              Yay! You took {{ diffTime }} seconds to solve this.
            </h2>
            <h2 class="text-base font-bold text-white" v-else>Oops!</h2>
          </div>
        </transition>
        <div v-if="!completed" class="flex gap-4 py-2">
          <img :src="jigsawImage" class="w-20 h-20 rounded-lg" />
          <div>
            <p class="text-nColorOrange font-ZuumeSemiBold text-xl">
              THIS IS YOUR HINT!
            </p>
            <div class="flex gap-1 items-center">
              <img
                :src="require('@/assets/images/bullet-star.svg')"
                class="w-1/12"
              />
              <p class="font-InterMedium text-xs">
                Tap on the pieces to rotate
              </p>
            </div>
            <div class="flex gap-1 items-center mt-1">
              <img
                :src="require('@/assets/images/bullet-star.svg')"
                class="w-1/12"
              />
              <p class="font-InterMedium text-xs">
                Drag the Pieces to move around
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimeElapsed ref="timeElapsedRef" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Counter from "@/components/elements/Counter.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TimeElapsed from "@/components/elements/TimeElapsed.vue";

export default {
  name: "Jigsaw",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, TimeElapsed },
  created() {
    const img = new Image();
    img.src = this.jigsawImage;

    img.addEventListener("load", (e) => {
      this.assignImageWidth(e.target);
    });
    return this.loadJigsawLibrary();
  },
  mounted() {
    setTimeout(() => {
      this.initJigsaw();
    }, 1500);
  },
  data() {
    return {
      jigsawImage: this.gameData.game_details.image,
      imgWidth: null,
      imgHeight: null,
      gameTime: null,
      completed: false,
      loading: true,
      started: false,
      solved: false,
      initialTime: {
        sec: 0,
        minute: 2,
        milliseconds: 0,
      },
      displayTime: {
        sec: 0,
        minute: 2,
        milliseconds: 0,
      },
      totalTimeInMilliseconds: 120 * 1000,
    };
  },
  methods: {
    loadJigsawLibrary() {
      return new Promise((resolve, reject) => {
        this.$loadScript("/library/experiences/jigsaw/js/sound.js")
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/event-emiter.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-event.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-puzzle.min.js"
            );
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    initJigsaw() {
      const vue = this;
      this.started = true;
      this.$nextTick(() => {
        var sound = new game.Sound(
          "/library/experiences/jigsaw/sound/click",
          10
        );
        var jsaw = new jigsaw.Jigsaw({
          defaultImage: vue.jigsawImage,
          image: jigsaw.GET["image"],
          maxWidth:
            vue.imgWidth > window.innerWidth * 0.75
              ? window.innerWidth * 0.75
              : vue.imgWidth,
          maxHeight:
            vue.imgHeight > window.innerHeight * 0.75
              ? window.innerHeight * 0.75
              : vue.imgHeight,
          spread: 0.5,
          defaultPieces: 6,
          numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
          piecesNumberTmpl: "%d Pieces",
          shuffled: true,
        });

        if (jigsaw.GET["image"]) {
          jsaw.set_image(jigsaw.GET["image"]);
        }

        jsaw.eventBus.on(jigsaw.Events.PIECES_CONNECTED, function () {
          sound.play();
        });

        jsaw.eventBus.on(jigsaw.Events.JIGSAW_COMPLETE, function () {
          vue.solved = true;
          vue.puzzleComplete();
        });

        this.countDown();
      });
    },

    puzzleComplete() {
      this.completed = true;
      this.gameTime = this.calculateTimeTaken();
      this.submitScore(this.gameTime);
    },

    assignImageWidth(image) {
      this.imgWidth = image.width;
      this.imgHeight = image.height;
    },
    countDown() {
      this.timer = setInterval(() => {
        if (
          this.displayTime.minute == 0 &&
          this.displayTime.sec == 0 &&
          this.displayTime.milliseconds == 0
        ) {
          clearInterval(this.timer);
          this.submitScore(this.totalTimeInMilliseconds);
        } else {
          if (this.displayTime.milliseconds == 0) {
            if (this.displayTime.sec == 0) {
              this.displayTime.sec = 59;
              this.displayTime.minute--;
            } else {
              this.displayTime.sec--;
            }
            this.displayTime.milliseconds = 99; // Reset milliseconds to 99
          } else {
            this.displayTime.milliseconds--;
          }
        }
      }, 10);
    },
    submitScore(timeTaken) {
      ApiService.post(apiResource.saveJigsawScore, {
        business_slug: this.$route.params.business,
        time: timeTaken.toString(),
        schedule_id: this.gameData.schedule_id,
        game_session_id: this.gameData.game_session_id,
      })
        .then(() => {
          const url = new URL(window.location.href);
          url.searchParams.set("redirect", "gameover");
          url.searchParams.set(
            "game_session_id",
            this.gameData.game_session_id
          );
          window.location.href = url.toString();
        })
        .catch(() => {
          this.$refs.timeElapsedRef.startTimer();
        });
    },
    calculateTimeTaken() {
      clearInterval(this.timer);
      const diffMilliseconds =
        (this.initialTime.minute - this.displayTime.minute) * 60000 +
        (this.initialTime.sec - this.displayTime.sec) * 1000 +
        (this.initialTime.milliseconds - this.displayTime.milliseconds);
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped>
@import url("/library/experiences/jigsaw/css/style.css");
.wrapper {
  height: 100dvh;
}
.jigsaw-container,
.jigsaw-loader {
  width: 20rem;
  height: 24rem;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all ease-in-out 0.5s 0.5s;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all ease-in-out 0.5s;
}
@media (min-width: 768px) {
  .jigsaw-container {
    height: 42rem;
  }
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}
.loader-wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
</style>
